import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import { InvoiceModel }              from '../models/invoice';
import { PropertyPipe }              from '../../table/pipes/property.pipe';
import { TableColumn }               from '../../table/types/table-column';
import { TransactionCenterJobModel } from '../../transaction-center/models/transaction-center.job';

@Component({
  selector   : 'wor-invoice-contact-status',
  styles: [ require('./invoice.contact-status.component.scss') ],
  template: require('./invoice.contact-status.component.html')
})
export class InvoiceContactStatusComponent implements OnInit {
  invoice : InvoiceModel;

  @Input() column : TableColumn;
  @Input() record : TransactionCenterJobModel;

  constructor (
    private propertyPipe: PropertyPipe
  ) {}

  ngOnInit () : void {
    /**
     * Sometimes the record that's passed in contains the invoice
     * in a nested object. If that is the case then a field value
     * will be passed in.
     *
     * When it is passed in, extract the value and set the invoice
     * variable. When it isn't, set the invoice to the record
     * directly.
     */
    this.invoice = this.column.field
      ? this.propertyPipe.transform(this.column.field, this.record)
      : this.record;
  }

  getIcon () : string {
    return this.invoice.email_sent && this.invoice.sms_sent
      ? 'email-and-sms'
      : this.invoice.email_sent
        ? 'email'
        : this.invoice.sms_sent
          ? 'sms'
          : '';
  }
}
