import 'angular';

import 'bootstrap-legacy';

import '../vendor/jquery-noty/jquery.noty.min';
import '../vendor/chosen/chosen.min';
import '../vendor/sprintf/sprintf';

import './core/core.module';

angular.module('app', [
  'app.core'
]);
