import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { UnitConditionFilter } from './unit.condition.filter.ajs';
import { UnitConditionPipe }   from './unit.condition.pipe';

angular.module('app-lite.units', [])
  .filter('unitCondition', UnitConditionFilter)
  .service('unitConditionPipe', downgradeInjectable(UnitConditionPipe));
