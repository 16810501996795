import angular from 'angular';

import '../../vendor/chosen/chosen.min';
import '../../vendor/jquery-noty/jquery.noty.min';
import '../../vendor/jquery-noty/noty_theme_default.css';

import HelpVideosHtml from './templates/help-videos.html';
import widthFunctions from '../../vendor/simpliq-theme/widthFunctions';

export function CoreLiteCtrl (
  $filter,
  $rootScope,
  $scope,
  $state,
  $transitions,
  $translate,
  $uibModal,
  $uibModalStack,
  $urlService,
  Company,
  confirm,
  deviceDetector,
  messages,
  sessionObjAPI,
  workshopAPI
) {

  $scope.date  = new Date();
  $scope.today = $filter('date')($scope.date, 'yyyy-MM-dd');
  $scope.user  = sessionObjAPI.base().username;

  $rootScope.beta        = $urlService.config.host().indexOf('beta') >= 0;
  $rootScope.company     = sessionObjAPI.company();
  $rootScope.deviceData  = deviceDetector;
  $rootScope.session     = sessionObjAPI.base();
  $rootScope.sessionApi  = sessionObjAPI;
  $rootScope.sidebarOpen = true;
  $rootScope.usBased     = $rootScope.company.subscription_subdomain === 'ari-network-services';

  $rootScope.Company = new Company();
  $rootScope.User    = sessionObjAPI.user();

  $rootScope.pluralize = function ( value = '' ) {
    return $filter('pluralize')(value);
  };

  $scope.help = {
    videos: function () {
      $uibModal.open({
        templateUrl: HelpVideosHtml,
        controller: ['$scope', '$uibModalInstance', '$rootScope', function ($scope, $uibModalInstance, $rootScope) {
          $scope.os = $rootScope.deviceData.os;
          $scope.close = function () {
            $uibModalInstance.dismiss();
          };
        }]
      });
    }
  };

  $scope.notifications = {
    dismiss (noty, evt) {
      evt.stopPropagation();

      workshopAPI.get('/company/dismiss_notification', noty.id)
      .then(warnings => {
        $rootScope.warnings = warnings;
      });
    },
    dismissAll (evt) {
      evt.stopPropagation();

      workshopAPI.get('/company/dismiss_notification', '*')
      .then(warnings => {
        $rootScope.warnings = warnings;
      });
    }
  };

  $scope.activateWorkshop = function () {
    $scope.Company.account.activate()
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.USER_ACTIVATED'), 'success');
      $state.reload();
    });
  };

  $scope.checkRights = function(controller) {
    return ( $rootScope.rights.indexOf(controller) > -1 );
  };

  $scope.companyInfo = function() {
    if (!sessionObjAPI.base()) {
      return false;
    }

    return sessionObjAPI.base().company_settings;
  };

  // Checks what page is loaded and highlights the right item in the sidebar
  $scope.isActive = function (viewLocation) {
    return ($urlService.path().indexOf(viewLocation) >= 0);
  };

  $scope.isBeta = function () {
    return $rootScope.beta;
  };

  $scope.isBoschPuppet = function() {
    if (!sessionObjAPI.base()) {
      return false;
    }
    else if (sessionObjAPI.base().puppet_session && sessionObjAPI.company().branding_group == 'bosch' && !sessionObjAPI.dealer_superuser()) {
      return true;
    }
    else {
      return false;
    };
  };

  $scope.navigateTo = function ( state = 'app' ) {
    $state.go(state);
  };

  // minimizes or expands a certain view.
  // when false is passed to getContent, it indicates
  // that the content element is being passed
  // Otherwise the call was most likely passed
  // from a button so the content element must be found
  $scope.toggleView = function(element, getContent) {
    if (getContent) {
      var content = element.target.parentElement.parentElement.parentElement.nextElementSibling;
      var chevron = $(element.currentTarget).find('#chevron');
    }
    else {
      var content = element;
      var chevron = element.parent().find('#chevron');
    }

    if ($(content).is(':visible')) {
      $(chevron).removeClass('fa-chevron-down').addClass('fa-chevron-up');
    }

    else {
      $(chevron).removeClass('fa-chevron-up').addClass('fa-chevron-down');
    }

    $(content).slideToggle(200, function() {
      widthFunctions();
      $('.chosen-grid').chosen();
    });
  };

  $transitions.onStart({}, transition => {
    /**
     * When the route changes to a different view,
     * make sure all modals have been dismissed.
     */
    if (transition.from().name !== transition.to().name) {
      $uibModalStack.dismissAll();
    }

    // There are a lot of mousedown events dealing with the search boxes
    // so everytime the view changes, the watches need to be reset
    $(document).off('mousedown');
  });

  // Before the location changes, check if there is a form
  // if there is then check if it is pristine. If it is, then
  // carry on normally. If the form has been touched, stop
  // navigation and show a pop up warning
  $transitions.onStart({}, transition => {
    if (angular.element('#form').length) {
      const form = angular.element('#form').scope().form;

      if ( form && transition.from().name === transition.to().name ) {
        form.$setPristine();
      }

      if (!$rootScope.changeToRoute && form && !form.$pristine) {
        confirm
          .generic($translate.instant('JS_SPACE.CONFIRM.UNSAVED_DATA'))
          .then(() => {
            $state.go(transition.to().name, transition.params(), {
              inherit: false
            });

            form.$setPristine();

            $rootScope.changeToRoute = true;
          })
          .catch(() => $rootScope.changeToRoute = false);

        return false;
      }
    }
  });

  $transitions.onSuccess({}, () => {
    $rootScope.changeToRoute = false;
  });

  if ($rootScope.company.first_login) {
    $scope.Company.firstLogin().then(function (data) {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');
    }, function (err) {
      messages.error(err);
    });
  }
}