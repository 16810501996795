import 'angular';

import '../../vendor/simpliq-theme/simpliq-theme.module';

import '../../app-lite/app.module.ajs';

import { CoreStates }          from './core.states.ajs';

import { CoreCtrl }             from './core';
import { CoreConfirmationCtrl } from './core.confirmation';


angular.module('app.core', [
  'simpliq-theme',
  'app-lite'
]).config(CoreStates)
  .controller('CoreConfirmationCtrl', CoreConfirmationCtrl)
  .controller('CoreCtrl', CoreCtrl);
