export function CoreConfirmationCtrl ($http, $rootScope, $state, $stateParams, $urlService, $window, API) {
  var vm = this;

  $window.scroll(0, 0);

  if ($stateParams.id) {
    $http.get(`${API.url}/system/retrieve_public_booking_diary_link/${$stateParams.tag}/${$stateParams.id}`).then(function (data) {
      vm.data = data.data[0];

      return $http.get(`${API.url}/system/public_booking_diary_settings/${$urlService.path().slice(1)}${vm.data.token}`);
    }).then(function (data) {
      $rootScope.branding = data.data;
    });;
  }
  else {
    vm.data = $stateParams;

    $http.get(`${API.url}/system/public_booking_diary_settings/${vm.data.tag}${vm.data.token}`).then(function (data) {
      $rootScope.branding = data.data;
    });
  }

};
