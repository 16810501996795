export function CoreCtrl ($http, $state, $rootScope, $stateParams, $urlService, API, messages, contactTypes) {
  var vm = this;

  vm.contactTypes = contactTypes;
  vm.params       = $stateParams;
  vm.booking      = {};

  vm.formatDate = function (date) {
    return moment(date).format('MMMM Do');
  };

  vm.formatWeekday = function (date) {
    return moment(date).format('dddd');
  };

  vm.getNextWeek = function () {
    const date = moment(Object.keys(vm.dates)[0]).add(7, 'days').format('YYYY-MM-DD');

    $http.get(`${API.url}/schedules/public_booking_diary/${$stateParams.tag}${vm.data.token}/${vm.booking.appointment_type || '*'}/${date}`)
      .then(function (data) {
        vm.dates = data.data;
      }, function (error) {
        messages.error(error);
      });
  };

  vm.getPreviousWeek = function () {
    var date = moment(Object.keys(vm.dates)[0]).subtract(7, 'days').format('YYYY-MM-DD');

    $http.get(`${API.url}/schedules/public_booking_diary/${$stateParams.tag}${vm.data.token}/${vm.booking.appointment_type || '*'}/${date}`)
      .then(function (data) {
        vm.dates = data.data;
      }, function (error) {
        messages.error(error);
      });
  };

  vm.init = function () {
    $http.get(`${API.url}/system/public_booking_diary_settings/${$urlService.path().slice(1)}${vm.data.token}`)
      .then(function (data) {
        $rootScope.branding = data.data;

        // get appointment types
        $http.get(`${API.url}/system/public_event_appointment_types/${$stateParams.tag}${vm.data.token}`)
          .then(function (data) {
            vm.appointmentTypes         = data.data;
            vm.booking.appointment_type = vm.data.appointment_type || '';
          });

        // get body types
        $http.get(`${API.url}/system/public_vehicle_body_types/${$stateParams.tag}${vm.data.token}`)
          .then(function (data) {
            vm.bodyTypes = data.data;
          });

        // get dates
        $http.get(`${API.url}/schedules/public_booking_diary/${$stateParams.tag + vm.data.token}/${vm.data.appointment_type_id || '*'}/${moment(vm.data.date || new Date()).format('YYYY-MM-DD')}`)
        .then(function (data) {
          vm.dates = data.data;
        });

        // get defaults
        $http.get(`${API.url}/system/public_customer_and_vehicle_details/${$stateParams.tag + vm.data.token}/${vm.data.customer_id || 0}/${vm.data.vehicle_id  || 0}`)
        .then(function (data) {
          vm.booking = angular.extend(vm.booking, data.data);
        });

        if ($rootScope.branding.company_variable_fields.plate_number_name) {
          vm.regoLabel = $rootScope.branding.company_variable_fields.plate_number_name;
        }
        else {
          vm.regoLabel = $rootScope.branding.country_settings.rego_label;
        };

        $rootScope.loaded = true;
      });
  };

  vm.isPastDate = function (date) {
    return moment(date).diff(moment().subtract(1, 'days')) < 0;
  };

  vm.save = function (form, date) {
    if (form.$valid) {
      vm.booking.requested_date = date;

      $http.post(`${API.url}/event_request`, {
        event_request: vm.booking,
        company_token: $stateParams.tag + vm.data.token
      }).then(function (data) {
          messages.show('Booking Request Sent', 'success');

          $state.go('app.confirmation', {
            tag  : $stateParams.tag,
            token: vm.params.token
          });
        }, function (error) {
          messages.error(error);
        });
    } else {
      messages.clientError(form);
    }
  };

  vm.updateAvailability = function () {
    $http.get(`${API.url}/schedules/public_booking_diary/${$stateParams.tag + vm.data.token}/${vm.booking.appointment_type || '*'}/${moment(vm.params.date || new Date()).format('YYYY-MM-DD')}`)
    .then(function (data) {
        vm.dates = data.data;
      }, function (error) {
        messages.error(error);
      });
  };

  if (vm.params.id) {
    $http.get(`${API.url}/system/retrieve_public_booking_diary_link/${$stateParams.tag}/${vm.params.id}`)
    .then(function (data) {
      vm.data = data.data[0];

      vm.init();
    });
  }
  else {
    vm.data = $stateParams;

    vm.init();
  }
};
