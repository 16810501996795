
        var result = require("!!../../../../node_modules/css-loader/dist/cjs.js??ref--15-1!../../../../node_modules/sass-loader/dist/cjs.js??ref--15-2!../../../../node_modules/sass-resources-loader/lib/loader.js??ref--15-3!./invoice.contact-status.component.scss");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    