import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitCondition'
})
export class UnitConditionPipe implements PipeTransform {
  constructor (
    @Inject('$translate')
    private $translate: ng.translate.ITranslateService
  ) {}

  transform( isNew = false ) : string {
    return isNew
      ? this.$translate.instant('DEALERSHIP_SPACE.NEW')
      : this.$translate.instant('DEALERSHIP_SPACE.USED');
  }
}
