import { ApiModel } from '../../app-lite/api/models/api.model';

import ConfirmationHtml from './core.confirmation.html';
import CoreHtml         from './core.html';

export function CoreStates (
  $stateProvider : ng.ui.IStateProvider,
  $urlRouterProvider : ng.ui.IUrlRouterProvider
) {
  $stateProvider
  .state({
    name      : 'app',
    redirectTo: 'app.core',
    template  : '<ui-view></ui-view>',
    url       : '',
    resolve   : {
      translateReady: ( $translate : ng.translate.ITranslateService ) : ng.IPromise<any> => $translate.onReady()
    }
  })
  .state({
    controller  : 'CoreConfirmationCtrl',
    controllerAs: 'ctrl',
    name        : 'app.confirmation',
    templateUrl : ConfirmationHtml,
    url         : '/confirm?id&tag&token',
  })
  .state({
    controller  : 'CoreCtrl',
    controllerAs: 'ctrl',
    name        : 'app.core',
    params      : {
      tag: { squash: true, value: null }
    },
    templateUrl: CoreHtml,
    url        : '/:tag?token&date&id',
    resolve    : {
      contactTypes: ( $http : ng.IHttpService, API : ApiModel ) : ng.IPromise<any> =>
        $http
        .get(`${API.url}/system/event_request_contact_types`)
        .then((data) => data.data)
    }
  });

  $urlRouterProvider.otherwise('/');
}