import { Table } from '../table/types/table';

export class VendorInvoicesForPaymentCtrl {
  areAllSelected            = false;
  selectedItems: Array<any> = [];

  tableSettings : Table = {
    columns: [
      {
        classes: 'text-left',
        field  : 'vendor_invoice_number',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        },
      },
      {
        classes: 'text-left',
        field  : 'post_date',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate'
      },
      {
        classes: 'text-left',
        field  : 'total',
        header : {
          text: this.$translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency'
      },
      {
        classes: 'text-left',
        field  : 'balance_due',
        header : {
          text: this.$translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe: 'globalCurrency'
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    private $translate: ng.translate.ITranslateService,
    public invoices   : Array<any>
  ) {}

  handleInvoiceSelection () : void {
    this.selectedItems = this.invoices.filter(invoice => invoice.selected);

    this.areAllSelected = this.invoices.length === this.selectedItems.length;
  }

  toggleAll () : void {
    this.invoices.forEach(invoice => {
      invoice.selected = !this.areAllSelected;
    });

    this.areAllSelected = !this.areAllSelected;
    this.selectedItems  = this.areAllSelected
      ? this.invoices
      : [];
  }
}
